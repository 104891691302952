<template>
  <div class="custom-chart-component">
    <div class="chart-box">
      <mdb-doughnut-chart
        class="chart"
        ref="chartSalaryEmploye"
        :data="doughnutChartData"
        :options="doughnutChartOptions"
        :width="width"
        :height="height"
      ></mdb-doughnut-chart>
    </div>
    <div class="chart-label">
      <div v-for="(lab, index) in label" :key="lab.id">
        <b-button
          :title="lab"
          :style="'background-color:' + datasets.backgroundColor[index]"
        ></b-button
        ><span class="ml-2">{{ lab }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mdbDoughnutChart } from 'mdbvue';
import 'chartjs-plugin-labels';
export default {
  props: {
    width: {
      type: Number
    },
    height: {
      type: Number
    },
    label: {
      type: Array
    },
    data: {
      type: Array
    },
    datasets: {
      type: Object
    },
    displayLabel: {
      default: false
    }
  },
  components: {
    mdbDoughnutChart
  },
  data() {
    return {
      doughnutChartData: {
        labels: this.label,
        datasets: [
          {
            data: this.data,
            backgroundColor: this.datasets.backgroundColor,
            hoverBackgroundColor: this.datasets.hoverBackgroundColor
          }
        ]
      },
      doughnutChartOptions: {
        responsive: false,
        legend: {
          display: this.displayLabel,
          position: 'bottom'
        },
        plugins: {
          labels: {
            render: 'percentage',
            showActualPercentages: true,
            fontColor: 'white',
            arc: true,
            fontSize: 10
          },
          datalabels: {
            formatter: value => {
              return value + 'euro';
            }
          }
        }
      }
    };
  },
  watch: {
    data: function() {
      this.doughnutChartData.labels = this.label;
      this.doughnutChartData.datasets[0]['data'] = this.data;
      this.$nextTick(() => {
        this.$refs['chartSalaryEmploye'].renderChart(
          this.doughnutChartData,
          this.doughnutChartOptions
        );
      });
    }
  }
};
</script>

<style scoped lang="scss">
.custom-chart-component {
  display: block;
  width: 100%;
  height: calc(100vh - 346px);
  .boxclick {
    border: 1px solid #88878742;
    box-shadow: 0px 3px 6px #aaaaaa42;
    cursor: pointer;
  }
  .chart-label-position {
    align-items: initial !important;
    flex-direction: row !important;
  }
  .chart-label {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    overflow-x: auto;
    max-height: calc(100% - 124px);
    div {
      background-color: #f6f5fb;
      border-radius: 27px;
      min-width: 90% !important;
      margin: 3px;
      padding: 3px;
      cursor: pointer;
      display: flex;
      align-items: center;
      button {
        width: 50px;
        height: 21px;
        border: none;
        margin-left: 2px;
        font-size: 9px;
        padding: 1px;
        border-radius: 19px;
        color: #fff;
        &:focus,
        &.focus {
          box-shadow: none;
        }
        span {
          color: #fff;
          padding: 0px;
          margin: 0px;
          font-size: 9px;
          font-family: 'Montserrat', sans-serif;
        }
      }

      span {
        color: #5d5d5d;
        padding: 0px;
        margin: 0px;
        font-size: 9px;
        font-family: 'Montserrat', sans-serif;
      }
    }
  }
}
@media screen and (max-width: 1035px) {
  .custom-chart-component {
    .chart-label {
      padding: 7px 0px;
    }
  }
}
@media screen and (max-width: 768px) {
  .custom-chart-component {
    width: 58%;
    .chart-label {
      width: 50%;
      padding: 0px;
      div {
        width: 50%;
        button {
          width: 26px;
          height: 13.5px;
          border: none;
          margin-left: 4px;
          &:focus,
          &.focus {
            box-shadow: none;
          }
        }
        span {
          font-size: 8px;
        }
      }
    }
  }
}
@media screen and (max-width: 475px) {
  .custom-chart-component {
    .chart-label {
      width: 100%;
      height: 152px;
    }
  }
}
@media screen and (max-width: 375px) {
  .custom-chart-component {
    .chart-label {
      height: 222px;
    }
  }
}

@media screen and (max-width: 320px) {
  .custom-chart-component {
    .chart-label {
      height: 244px;
    }
  }
}
</style>
